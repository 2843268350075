.compare {
  table {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 13px 21px -5px rgba(0, 0, 0, 0.05);
    border: 1px solid #eee;
    font-size: 18px;
    table-layout: fixed;

    tr > td,
    tr > th {
      padding-top: 25px;
      padding-bottom: 25px;
      text-align: center;
    }

    th[scope="row"] {
      font-size: 20px;
      color: #393c45;
      font-weight: normal;
      background-color: #f9f9f9;
      border: 1px solid #eee;
      text-align: left;
      padding-left: 45px;
    }

    tr {
      &.condition {
        color: #fff;
        font-size: 20px;
      }

      &.colors span {
        height: 20px;
        width: 20px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 100%;
      }
    }

    thead {
      th {
        font-size: 20px;
        color: #393c45;
        font-weight: normal;
      }
    }
  }

  .thead-default th {
    background-color: #fff;
  }
}

.centercompare {
  text-align: -webkit-center;
}

.split-item {
  background: #10436e;
  color: #fff;
  text-align: left !important;
  padding-left: 0.4rem;
}
